<template>
  <div class="fb-type">
    <div class="wrap clearFix" :class="{ all: !question }">
      <div class="bar_left left fl">
        <div class="item title">问题类型：</div>
        <div class="item_box">
          <div
            class="item"
            :class="{ active: item.id == select }"
            v-for="(item, index) in typeList"
            @click="selectType(item)"
            :key="index"
          >
            <span>{{ item.title }}</span>
          </div>
        </div>
      </div>
      <div class="bar_right right fr">
        <p class="title">常见问题及解决方案</p>
        <div class="answer" v-for="(item, index) in answerList" :key="index">
          <p class="title"><b>问题:&emsp;</b>{{ item.question }}</p>
          <p class="solution"><b>解决方案:&emsp;</b>{{ item.solution }}</p>
        </div>
        <p class="tip" v-if="answerList.length <= 0">暂无该问题分类解决方案~</p>
      </div>
    </div>
    <div class="button_box">
      <button
        :disabled="!Boolean(select)"
        v-if="question"
        class="contact"
        @click="next"
      >
        {{ select ? "未能解决问题？联系客服" : "请选择问题类型" }}
      </button>
    </div>
  </div>
</template>

<script>
// 需要哪些模块按需引入
import { toRefs, reactive, onMounted, getCurrentInstance } from "vue";
import { useRoute } from "vue-router";
import { knowledgeList } from "_API_/api.services";
import { getStore } from "@/utils/store";
export default {
  setup() {
    const state = reactive({
      select: "", //类型选取
      typeList: [], //类型列表
      answerList: [], // 解决方案列表
      question: true //按钮展示
    });
    // 这里的ctx  类似于vue2的this
    const { proxy: ctx } = getCurrentInstance();
    // 路由 this.$router
    const { $tool: tool, $store: store, $router: router } = ctx;
    //调用useRoute,获取页面携带的参数
    const route = useRoute();
    const methods = {
      //类型选取操作
      selectType(item) {
        if (state.select == item.id) {
          return;
        }
        state.select = item.id;
        ctx.getAnswer(item.id);
      },
      //获取类型列表
      getTypeList() {
        //获取SessionStorage的类型列表
        state.typeList = getStore({ name: "typeList" });
        if (state.typeList.length > 0) {
          ctx.getAnswer(state.typeList[0].id);
        }
      },
      //路由跳转
      next() {
        router.push({
          name: "fb-new",
          query: {
            type: state.select
          }
        });
      },
      //获取反馈类型列表
      getAnswer(id) {
        let conf = new FormData(); //创建一个新的 FormData 对象。
        conf.append("groupId", id); //向 FormData 中添加新的属性值
        knowledgeList(conf).then(({ data: res }) => {
          if (res.code != 0) {
            tool.toast(
              {
                msg: res.msg
              },
              () => {}
            );
            return;
          }
          state.answerList = res.data;
        });
      }
    };
    onMounted(() => {
      ctx.getTypeList();
      // 当页面是从问题详情过来的，隐藏提交工单按钮
      if (route.query.hide == 1) {
        state.question = false;
      }
    });
    return {
      ...toRefs(state),
      ...methods
    };
  }
};
</script>

<style lang="scss" scoped>
@import "@/style/theme/index";
.fb-type {
  height: 100vh;
  overflow: hidden;
  .clearFix:after {
    display: block;
    content: "";
    zoom: 1;
    clear: both;
  }
  .wrap {
    height: calc(100vh - 104px);
    .bar_left {
      overflow-x: hidden;
      overflow-y: auto;
    }
    .bar_right {
      overflow-x: hidden;
      overflow-y: auto;
    }
    .left {
      width: 30%;
      height: 100%;
      border-right: 1px solid #c0c0c0;
      background: #f0f0f0;
      .item_box {
        height: calc(100vh - 150px);
        overflow-y: auto;
        .item {
          height: 80px;
          line-height: 80px;
          padding: 0px 20px;
          text-align: center;
          border-bottom: 1px solid #fff;
          transition: all 0.25s;
          span {
            max-width: 100%;
            display: inline-block;
            font-size: 24px;
            line-height: 40px;
            word-break: break-all;
            vertical-align: middle;
          }
        }
        .item.active {
          @include background_color("background_color1");
          color: #fff;
        }
      }
      .item.title {
        font-size: 30px;
        font-weight: bold;
        padding-left: 10px;
        text-align: left;
      }
    }
    .right {
      width: 69%;
      height: 100%;
      background: #f5f5f5;
      > .title {
        font-size: 30px;
        font-weight: bold;
        padding-left: 10px;
        text-align: left;
        line-height: 80px;
      }
      .answer {
        padding: 10px 2px 10px;
        margin: 20px 0px;
        background: #fff;
        p.title {
          padding-left: 10px;
          border-left: 6px solid crimson;
          margin-bottom: 4px;
          word-break: break-all;
          font-size: 24px;
        }
        p.solution {
          color: #656565;
          font-size: 24px;
          word-break: break-all;
          margin-top: 20px;
        }
      }
      .tip {
        text-align: center;
        line-height: 80px;
        font-size: 24px;
      }
    }
  }
  .wrap.all {
    padding: 0;
  }
  .fl {
    float: left;
  }
  .fr {
    float: right;
  }
}
.button_box {
  background: #fff;
  height: 104px;

  .contact {
    font-size: 28px;
    width: 96%;
    height: 70px;
    color: #fff;
    display: block;
    text-align: center;
    margin-bottom: 16px;
    line-height: 70px;
    border: none;
    border-radius: 10px;
    position: fixed;
    bottom: 4px;
    left: 2%;
    @include background_color("background_color1");
  }
  .contact[disabled] {
    @include background_color("background_color2");
  }
}
</style>
